import { Box } from "@mui/material";
import Feature from "./Feature";
import CheckOutlined from "@mui/icons-material/CheckOutlined";
import { useTranslation } from "react-i18next";

const FeaturesGrid = () => {
  const { t } = useTranslation();

  return (
    <Box
      minWidth={"200px"}
      maxWidth={"1216px"}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        alignContent: "flex-start",
        gap: "24px 32px",
        alignSelf: "stretch",
        flexWrap: "wrap",
      }}
    >
      <Feature
        icon={<CheckOutlined />}
        title={t("homePage.features.interactiveDisplay.title")}
        description={t("homePage.features.interactiveDisplay.description")}
      />
      <Feature
        icon={<CheckOutlined />}
        title={t("homePage.features.StandardizedResults.title")}
        description={t("homePage.features.StandardizedResults.description")}
      />
      <Feature
        icon={<CheckOutlined />}
        title={t("homePage.features.ComposeYourFilters.title")}
        description={t("homePage.features.ComposeYourFilters.description")}
      />
      <Feature
        icon={<CheckOutlined />}
        title={t("homePage.features.OverlayEndemicityLayers.title")}
        description={t("homePage.features.OverlayEndemicityLayers.description")}
      />
      <Feature
        icon={<CheckOutlined />}
        title={t("homePage.features.TrustworthyDatasources.title")}
        description={t("homePage.features.TrustworthyDatasources.description")}
      />{" "}
      <Feature
        icon={<CheckOutlined />}
        title={t("homePage.features.Reporting.title")}
        description={t("homePage.features.Reporting.description")}
      />
      <Feature
        icon={<CheckOutlined />}
        title={t("homePage.features.RegularUpdates.title")}
        description={t("homePage.features.RegularUpdates.description")}
      />
      <Feature
        icon={<CheckOutlined />}
        title={t("homePage.features.VisualizeOwnData.title")}
        description={t("homePage.features.VisualizeOwnData.description")}
      />
    </Box>
  );
};

export default FeaturesGrid;
