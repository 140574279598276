import Stack from "@mui/material/Stack";
import HeroSection from "../components/home-page/HeroSection";
import Headline from "../components/home-page/Headline";
import FeaturesGrid from "../components/home-page/FeaturesGrid";
import Divider from "@mui/material/Divider";
import ContactForm from "../components/home-page/ContactForm";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

const HomePage = () => {
  return (
    <Stack>
      <Stack
        spacing={4}
        paddingY={4}
        alignItems={"center"}
        justifyContent={"flex-start"}
        alignSelf={"stretch"}
        sx={{ paddingX: { xs: 0, md: 8 } }}
      >
        <HeroSection />
        <Stack paddingY={10} paddingX={4} spacing={6} alignItems={"center"}>
          <Headline />
          <FeaturesGrid />
        </Stack>
        <Divider flexItem />
        <Stack direction={"column"} spacing={6} paddingY={10} paddingX={8}>
          <Typography variant="h4" textAlign={"center"}>
            {" "}
            Data Sources{" "}
          </Typography>
          <Typography variant="body1">
            Data are extracted monthly from peer-reviewed scientific
            publications and other published reports including the President's
            Malaria Initiative Country Insecticide Susceptibility Summaries (for
            Anopheles map) and VectorBase. The Aedes map includes data provided
            by Professor Hilary Ranson in 2016.
          </Typography>
          <Typography variant="body1">
            A new menu was added to the Anopheles map that displays modelled
            resistance layers from Professor Catherine Moyes' Geospatial
            Modelling of Insect Vectors (GMIV) group. The layers bridge
            insecticide resistance data gaps from surveillance data in
            sub-Saharan Africa and are made using a published geostatistical
            ensemble model . The mean mortality of Anopheles gambiae s.l. to
            alphacypermethrin, lambda-cyhalothrin, deltamethrin, permethrin, and
            DDT are estimated from 2005 to 2017. Probability layers developed by
            a collaboration between GMIV, LSTM, CHAI, and IR Mapper are also
            provided to show the likelihood that pyrethroid resistance in
            Anopheles gambiae s.l. (at district level) exceeds the WHO
            thresholds for susceptibility, confirmed resistance, or the 10-80%
            mortality criteria for deployment of piperonyl butoxide-treated nets
            that mitigate against the effects of metabolic resistance to
            pyrethroids.
          </Typography>
          <Typography variant="body1">
            We recognize that An. funestus s.l. is also an important malaria
            vector in Africa, however, data on An. funestus s.l. were
            insufficient to produce modelled pyrethroid resistance estimates. In
            areas where An. funestus s.l. are present, the layers showing
            resistance in An. gambiae s.l. should be considered alongside data
            on resistance in An. funestus s.l.
          </Typography>
          <Typography variant="body1">
            Additionally, the Anopheles map includes a menu that allows for
            download of analysis ready data. These are a series of geospatial
            datasets for insecticide resistance in malaria vectors that can be
            used to quantify insecticide resistance trends in time and space. To
            request the up-to-date dataset displayed on the site, contact us via
            info@irmapper.com.
          </Typography>
        </Stack>
        <Divider flexItem />
        <Stack direction={"column"} spacing={6} paddingY={10} paddingX={8}>
          <Typography variant="h4" textAlign={"center"}>
            {" "}
            Latest Updates{" "}
          </Typography>
          <Typography variant="body1" fontWeight={"bold"}>
            Updated Anopheles map
          </Typography>
          <Typography variant="body1">
            The Anopheles map now includes additional features for visualizing
            modelled insecticide resistance layers and resistance intensity data
            points.
          </Typography>
          <Typography variant="body1">
            Here is a longer description of the updates that have been made:
          </Typography>
          <List sx={{ listStyleType: "disc", pl: 2 }}>
              <ListItem sx={{ display: "list-item" }}>
                <ListItemText primary="Moyes CL, Athinya DK, Seethaler T, et al. Evaluating insecticide resistance across African districts to aid malaria control decisions. Proc Natl Acad Sci. August 2020:202006781." />
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <ListItemText primary="Hancock, P. A., Hendriks, C., Tangena, J. A., Gibson, H., Hemingway, J., Coleman, M., Gething, P. W., Cameron, E., Bhatt, S., & Moyes, C. L. (2020). Mapping trends in insecticide resistance phenotypes in African malaria vectors. PLoS Biology, 18(6), e3000633." />
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <ListItemText primary="Weiss, D. J., Lucas, T. C., Nguyen, M., Nandi, A. K., Bisanzio, D., Battle, K. E., ... & Gibson, H. S. (2019). Mapping the global prevalence, incidence, and mortality of Plasmodium falciparum, 2000-17: a spatial and temporal modelling study. The Lancet, 394(10195), 322-331." />
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <ListItemText primary="Battle, K. E., Lucas, T. C., Nguyen, M., Howes, R. E., Nandi, A. K., Twohig, K. A., ... & Gibson, H. S. (2019). Mapping the global endemicity and clinical burden of Plasmodium vivax, 2000-17: a spatial and temporal modelling study. The Lancet, 394(10195), 332-343." />
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <ListItemText primary="Messina, J. P., Kraemer, M. U., Brady, O. J., Pigott, D. M., Shearer, F. M., Weiss, D. J., ... & Brownstein, J. S. (2016). Mapping global environmental suitability for Zika virus. Elife, 5, e15272." />
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <ListItemText primary="Bhatt, S., Gething, P. W., Brady, O. J., Messina, J. P., Farlow, A. W., Moyes, C. L., ... & Myers, M. F. (2013). The global distribution and burden of dengue. Nature, 496(7446), 504-507." />
              </ListItem>
              <ListItem sx={{ display: "list-item" }}>
                <ListItemText primary="Nsoesie, E. O., Kraemer, M. U., Golding, N., Pigott, D. M., Brady, O. J., Moyes, C. L., ... & Hay, S. I. (2016). Global distribution and environmental suitability for chikungunya virus, 1952 to 2015. Eurosurveillance, 21(20), 30234." />
              </ListItem>
            </List>
          <Typography variant="body1">
            IR Mapper is a joint initiative. Data collection and collation is
            performed by Vestergaard, data proofreading is conducted by
            KEMRI-CGHR and the interactive map platform was developed by ESRI
            Eastern Africa (collectively “IR Mapper”).
          </Typography>
          <Box
            height={"455px"}
            alignSelf={"stretch"}
            sx={{
              position: "relative",
              backgroundImage: `url(/vest.png)`,
              backgroundSize: "contain",
              backgroundPosition: "50%",
              backgroundRepeat: "no-repeat",
            }}
          />
        </Stack>
      </Stack>
      <ContactForm />
    </Stack>
  );
};

export default HomePage;
