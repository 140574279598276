import DOMPurify from "dompurify";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import EmailOutlined from "@mui/icons-material/EmailOutlined";
import { useEffect, useState } from "react";

const ContactForm = () => {
  const { t } = useTranslation();

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessageError] = useState("");

  const maxFullNameLength = 100;
  const maxSubjectLength = 200;
  const maxMessageLength = 5000;

  useEffect(() => {
    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (email && !emailRegex.test(email)) {
      setEmailError("Invalid email format");
    } else {
      setEmailError("");
    }

    // Validate character limits
    if (fullName.length > maxFullNameLength) {
      setFullNameError(
        `Full name cannot exceed ${maxFullNameLength} characters`
      );
    } else {
      setFullNameError("");
    }

    if (subject.length > maxSubjectLength) {
      setSubjectError(`Subject cannot exceed ${maxSubjectLength} characters`);
    } else {
      setSubjectError("");
    }

    if (message.length > maxMessageLength) {
      setMessageError(`Message cannot exceed ${maxMessageLength} characters`);
    } else {
      setMessageError("");
    }

    // Check if all fields are filled
    if (
      fullName &&
      email &&
      subject &&
      message &&
      !emailError &&
      !fullNameError &&
      !subjectError &&
      !messageError
    ) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [
    fullName,
    email,
    subject,
    message,
    emailError,
    fullNameError,
    subjectError,
    messageError,
  ]);

  const handleMailToClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    window.open("mailto:info@irmapper.com", "_blank");
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    // Sanitize input
    const sanitizedFullName = DOMPurify.sanitize(fullName);
    const sanitizedEmail = DOMPurify.sanitize(email);
    const sanitizedSubject = DOMPurify.sanitize(subject);
    const sanitizedMessage = DOMPurify.sanitize(message);

    const data = {
      FullName: sanitizedFullName,
      Email: sanitizedEmail,
      Subject: sanitizedSubject,
      Message: sanitizedMessage,
    };

    const API_URL =
      process.env.NODE_ENV === "production"
        ? process.env.REACT_APP_API_URL
        : "";

    try {
      const response = await fetch(`${API_URL}/api/contact`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const contentType = response.headers.get("content-type");
      let result;
      if (contentType && contentType.includes("application/json")) {
        result = await response.json();
      } else {
        result = await response.text();
      }
      console.log("Form submitted successfully:", result);
      // Optionally, you can reset the form here
      setFullName("");
      setEmail("");
      setSubject("");
      setMessage("");
    } catch (error) {
      console.error("There was a problem with the form submission:", error);
    }
  };

  return (
    <Box
      padding={4}
      justifyContent={"center"}
      alignItems={"flex-start"}
      alignContent={"flex-start"}
      sx={{
        display: "flex",
        gap: "0px",
        flexWrap: "wrap",
        backgroundColor: "var(--blueGrey-50, #ECEFF1)",
      }}
    >
      <Stack
        minWidth={"280px"}
        maxWidth={"560px"}
        paddingX={3}
        paddingY={8}
        direction={"column"}
        spacing={6}
        alignItems={"center"}
        alignSelf={"stretch"}
        flex={"1 0 0"}
      >
        <Typography variant="h4">{t("homePage.contactForm.title")}</Typography>
        <Typography variant="body1">
          {t("homePage.contactForm.subtitle")}
        </Typography>
        <Button
          variant="text"
          size="large"
          color="primary"
          startIcon={<EmailOutlined />}
          onClick={handleMailToClick}
        >
          info@irmapper.com
        </Button>
      </Stack>
      <Stack
        minWidth={"280px"}
        maxWidth={"560px"}
        paddingRight={4}
        paddingY={8}
        direction={"column"}
        spacing={4}
        alignItems={"flex-start"}
        alignSelf={"stretch"}
        flex={"1 0 0"}
      >
        <TextField
          fullWidth
          variant="outlined"
          size="medium"
          label={t("homePage.contactForm.name")}
          required
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          inputProps={{ maxLength: maxFullNameLength }}
          error={!!fullNameError}
          helperText={fullNameError}
        />
        <TextField
          fullWidth
          variant="outlined"
          size="medium"
          label={t("homePage.contactForm.email")}
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={!!emailError}
          helperText={emailError}
        />
        <TextField
          fullWidth
          variant="outlined"
          size="medium"
          label={t("homePage.contactForm.subject")}
          required
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
          inputProps={{ maxLength: maxSubjectLength }}
          error={!!subjectError}
          helperText={subjectError}
        />
        <TextField
          fullWidth
          variant="outlined"
          multiline
          size="medium"
          label={t("homePage.contactForm.message")}
          minRows={4}
          required
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          inputProps={{ maxLength: maxMessageLength }}
          error={!!messageError}
          helperText={messageError}
        />
        <Button
          variant="contained"
          size="large"
          color="primary"
          disabled={!isFormValid}
          onClick={handleSubmit}
        >
          {t("homePage.contactForm.submit")}
        </Button>
      </Stack>
    </Box>
  );
};

export default ContactForm;
